import React, { useEffect, useState } from 'react';
import { Flex } from 'rebass';
import { Link, navigate } from 'gatsby';
import { Button, Form, FormGroup, Input } from 'reactstrap';
import { Auth } from 'aws-amplify';
import ErrorComponent from 'components/Error';
import * as yup from 'yup';

import Layout from 'components/layout/Layout';
import CardHeading from 'components/layout/CardHeading';
import CardFooter from 'components/layout/CardFooter';

import { fieldIsInvalid, fieldIsRequired, getValidationResult } from 'utils/tools';
import { MessageModal } from 'components/modals/Modals';
import User from 'utils/auth';
import LoggerUtils from 'utils/logger';
import useForm from 'hooks/useForm';
import useRequest from 'hooks/useRequest';
import FormFeedback from 'reactstrap/lib/FormFeedback';

const resetPasswordSchema = yup.object({
  isLoggedIn: yup.boolean(),
  authCode: yup
    .string()
    .label('Authorization Code')
    .when('isLoggedIn', {
      is: false,
      then: yup.string().required(fieldIsRequired),
    }),
  oldPassword: yup.string().when('isLoggedIn', {
    is: true,
    then: yup.string().required(fieldIsRequired),
  }),
  password: yup.string().label('New Password').min(8, 'Must have at least 8 characters').required(fieldIsRequired),
  password2: yup
    .string()
    .label('Confirm Password')
    .required(fieldIsRequired)
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
});

const sendLinkSchema = yup.object({
  forgotEmail: yup.string().label('Email').required(fieldIsRequired).email(fieldIsInvalid),
});

export const ForgotPassElements = ({
  defaultInputData = { forgotEmail: '', authCode: '', oldPassword: '', password: '', password2: '', isLoggedIn: false },
}) => {
  const [stage, setStage] = useState(0);
  const [{ fields, errors }, { onElementChange, onValidate, onChange }] = useForm({
    initialFields: defaultInputData,
    validator,
    onValid,
  });
  const { forgotEmail, authCode, password, password2, oldPassword } = fields;
  const [sendLink, sendLinkState] = useRequest({
    request: () => Auth.forgotPassword(forgotEmail),
    onSuccess: () => {
      setStage(1);
    },
  });
  const [resetWithCode, resetWithCodeState] = useRequest({
    request: () => Auth.forgotPasswordSubmit(forgotEmail, authCode, password),
    onSuccess: () => {
      LoggerUtils.info(`Forgot Password Successful`);
      toggleModal()
    },
  });
  const [resetWithUser, resetWithUserState] = useRequest({
    request: async () => {
      const user = await Auth.currentAuthenticatedUser();
      return Auth.changePassword(user, oldPassword, password);
    },
    onSuccess: () => {
      LoggerUtils.info(`Change Password Successful`);
      toggleModal()
    },
  });
  const [modalVisible, setModalVisible] = useState(false);
  const [heading, setHeading] = useState({
    title: '',
    desc: '',
  });

  useEffect(() => {
    (async function () {
      const loggedInUser = await User.isLoggedIn();
      if (loggedInUser) {
        setHeading({
          title: 'Reset Password',
          desc: '',
        });
        setStage(1);
        LoggerUtils.info(`User ${loggedInUser} is logged in`);
        onChange('isLoggedIn', true);
      } else {
        setHeading({
          title: 'Recover Password',
          desc: 'You can recover a lost or forgotten password by entering your email address and requesting a link.',
        });
      }
    })();
  }, []);

  const location = () => {
    if (!fields.isLoggedIn) {
      navigate('/');
      return;
    }
    navigate('/app/settings');
  };

  const msg = {
    class: 'primary',
    title: 'Password changed',
    message: (
      <div>
        Password successfully updated.
        <br />
        <br />
      </div>
    ),
    devMsg: '',
    buttons: ['Okay'],
    action: [location],
  };
  const resetError =
    resetWithUserState.error?.message === 'Incorrect username or password.'
      ? {
        message: 'Incorrect old password',
      }
      : resetWithUserState.error;
  return {
    forgotEmailField: {
      value: forgotEmail,
      onChange: onElementChange,
      invalid: errors.forgotEmail,
    },
    authCodeField: {
      value: authCode,
      onChange: onElementChange,
      invalid: errors.authCode,
    },
    oldPasswordField: {
      value: oldPassword,
      onChange: onElementChange,
      invalid: errors.oldPassword,
    },
    passwordField: {
      value: password,
      onChange: onElementChange,
      invalid: errors.password,
    },
    password2Field: {
      value: password2,
      onChange: onElementChange,
      invalid: errors.password2,
    },
    error: sendLinkState.error || resetWithCodeState.error || resetError,
    submitButton: {
      onClick: onValidate,
      disabled: sendLinkState.loading || resetWithCodeState.loading || resetWithUserState.loading,
    },
    modalVisible,
    msg,
    location,
    toggleModal,
    onValidate,
    heading,
    errors,
    stage,
    fields,
  };

  function toggleModal() {
    setModalVisible(!modalVisible);
  }
  function validator(data) {
    return getValidationResult(data, stage === 0 ? sendLinkSchema : resetPasswordSchema);
  }

  function onValid(data) {
    if (stage === 0) {
      return sendLink(data);
    }
    if (stage === 1 && data.isLoggedIn) {
      return resetWithUser(data);
    }
    if (stage === 1) {
      return resetWithCode(data);
    }
  }
};

const ForgotPass = (props) => {
  const {
    forgotEmailField,
    authCodeField,
    passwordField,
    password2Field,
    oldPasswordField,
    error,
    modalVisible,
    msg,
    toggleModal,
    heading,
    errors,
    fields,
    stage,
    submitButton,
    onValidate,
  } = ForgotPassElements(props);
  return (
    <Layout data-test="recover-password-component" hideIcons={!fields.isLoggedIn}>
      <CardHeading heading={heading} />
      <Form onSubmit={onValidate} className="my-4">
        {error && <ErrorComponent data-test="recover-password-component-error" errorMessage={error} />}
        {stage === 0 ? (
          <>
            <FormGroup>
              <Input type="email" name="forgotEmail" id="forgotEmail" placeholder="Email" {...forgotEmailField} />
              {errors.forgotEmail && <FormFeedback>{errors.forgotEmail}</FormFeedback>}
            </FormGroup>
            <Button color="primary" block {...submitButton}>
              {`${!submitButton.disabled ? 'Request Link' : 'Please wait...'}`}
            </Button>
          </>
        ) : (
          <>
            {fields.isLoggedIn ? (
              <FormGroup>
                <Input
                  type="password"
                  name="oldPassword"
                  id="oldPassword"
                  placeholder="Old Password"
                  {...oldPasswordField}
                />
                {errors.oldPassword && <FormFeedback>{errors.oldPassword}</FormFeedback>}
              </FormGroup>
            ) : (
              <FormGroup>
                <Input type="text" name="authCode" id="authCode" placeholder="Code" {...authCodeField} />
                {errors.authCode && <FormFeedback>{errors.authCode}</FormFeedback>}
              </FormGroup>
            )}
            <FormGroup>
              <Input type="password" name="password" id="password" placeholder="New Password" {...passwordField} />
              {errors.password && <FormFeedback>{errors.password}</FormFeedback>}
            </FormGroup>

            <FormGroup>
              <Input
                type="password"
                name="password2"
                id="password2"
                placeholder="Confirm New Password"
                {...password2Field}
              />
              {errors.password2 && <FormFeedback>{errors.password2}</FormFeedback>}
            </FormGroup>
            <Button color="primary" block {...submitButton}>
              {`${!submitButton.disabled ? 'Confirm Change Password' : 'Please wait...'}`}
            </Button>
          </>
        )}
        {heading.title === 'Recover Password' && (
          <Flex justifyContent="center">
            <Link to="/app/signin" className="text-center link-heading">
              Return to Sign In
            </Link>
          </Flex>
        )}
      </Form>

      {!fields.isLoggedIn && (
        <CardFooter>
          <p className="t-text">The link will be emailed to you and allow you to set a new password.</p>
        </CardFooter>
      )}

      <MessageModal
        title={msg.title}
        color={msg.class}
        modal={modalVisible} // current status
        setModal={toggleModal} // action to change Boolean
        buttons={msg.buttons}
        actions={msg.action}
      >
        <div className="text-center">
          <>{msg.message}</>
          <div style={{ width: '20px', height: '20px' }} />
        </div>
      </MessageModal>
    </Layout>
  );
};

export default ForgotPass;
